<template>
  <div>
    <Component
      :is="getType(component)"
      v-for="(component, index) in components"
      v-bind="$attrs"
      :key="index"
      v-editor-target="index"
      :data="component"
      :scroll-to-index="index"
      :index="index"
      :prev-component="components[index - 1]?.type"
      :next-component="components[index + 1]?.type"
    />
  </div>
</template>

<script setup lang="ts">
export type ComponentType = { type: string }

withDefaults(
  defineProps<{
    components: ComponentType[]
  }>(),
  {
    components: () => [],
  }
)

function getType(component: ComponentType) {
  if (!component.type) {
    console.error(
      'ComponentLoader: Could not find component with type of',
      component
    )
    return ''
  }

  return 'component-' + component.type.replaceAll(/_/g, '-')
}
</script>
